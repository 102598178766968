define("discourse/plugins/discourse-version-tag-priority/discourse/controllers/admin-plugins-version-tag", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _controller, _object, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsVersionController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "tentacleVisible", [_tracking.tracked], function () {
      return false;
    }))();
    #tentacleVisible = (() => (dt7948.i(this, "tentacleVisible"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fromCategoryId", [_tracking.tracked], function () {
      return 4;
    }))();
    #fromCategoryId = (() => (dt7948.i(this, "fromCategoryId"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toCategoryId", [_tracking.tracked], function () {
      return 0;
    }))();
    #toCategoryId = (() => (dt7948.i(this, "toCategoryId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "newAuthorId", [_tracking.tracked]))();
    #newAuthorId = (() => (dt7948.i(this, "newAuthorId"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isLoadingListTopic", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoadingListTopic = (() => (dt7948.i(this, "isLoadingListTopic"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "listTopics", [_tracking.tracked], function () {
      return [];
    }))();
    #listTopics = (() => (dt7948.i(this, "listTopics"), void 0))();
    async moveAllTopic() {
      try {
        const res = await (0, _ajax.ajax)(`/version_tags/move_all_topic`, {
          type: "POST",
          data: {
            from_category_id: this.fromCategoryId,
            to_category_id: this.toCategoryId
          }
        });
        alert(`Move all topic successfully`);
        this.loadListTopicFromCategoryId(this.fromCategoryId);
      } catch (error) {
        alert(`Move all topic fail`);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "moveAllTopic", [_object.action]))();
    showTopics() {
      this.tentacleVisible = true;
      this.loadListTopicFromCategoryId(this.fromCategoryId);
    }
    static #_8 = (() => dt7948.n(this.prototype, "showTopics", [_object.action]))();
    changeOldActivity() {
      this.callChangeOldActivity(this.fromCategoryId);
    }
    static #_9 = (() => dt7948.n(this.prototype, "changeOldActivity", [_object.action]))();
    async onChangeDate(props, date) {
      const convertDate = this.convertDateWithTime(date);
      const data = {
        created_at: convertDate,
        updated_at: convertDate
      };
      if (this.newAuthorId) {
        data.user_id = this.newAuthorId;
      }
      try {
        const res = await (0, _ajax.ajax)(`/version_tags/change_topic/${props.topicId}`, {
          type: "PUT",
          data
        });
        alert(`Change date success for topic ${props.topicId}`);
      } catch (error) {
        alert(`Change date fail for topic ${props.topicId}`);
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "onChangeDate", [_object.action]))();
    async loadListTopicFromCategoryId(categoryId) {
      this.isLoadingListTopic = true;
      try {
        const res = await (0, _ajax.ajax)(`/c/${categoryId}.json`);
        this.listTopics = res.topic_list.topics;
      } finally {
        this.isLoadingListTopic = false;
      }
    }
    async callChangeOldActivity(categoryId) {
      try {
        const res = await (0, _ajax.ajax)(`/version_tags/change_old_activity`, {
          type: "POST",
          data: {
            category_id: categoryId
          }
        });
        alert(`Change old activity of all topic from category ${categoryId} successfully`);
      } catch (error) {
        alert(`Change old activity of all topic fail`);
      }
    }
    convertDateWithTime(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
      return date.toISOString();
    }
  }
  _exports.default = AdminPluginsVersionController;
});